import React from 'react'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from '../components/Common/PageBanner'
import Footer from "../components/App/Footer"
import ServicesOne from '../components/Services/ServicesOne'
import StartProject from '../components/Common/StartProject'
import { useTranslation} from "gatsby-plugin-react-i18next";
import { graphql } from "gatsby";

const Services = ({data}) => {
  const {t} = useTranslation();
    return (
        <Layout>
            <Navbar />

            <PageBanner
                pageTitle={t('navBar.services')}
                homePageText={t('navBar.home')}
                homePageUrl="/" 
                activePageText={t('navBar.services')}
            />

            <ServicesOne data={data}/>

            <StartProject />
            
            <Footer />
        </Layout>
    );
}

export default Services

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["common", "index"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    allStrapiServiceSolution(filter: {locale: {eq: $language}}) {
        nodes {
            icon
            title
            shortText
            slug
        }
    }
  }
`;